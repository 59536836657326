import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import moment from "moment";

const CandidatoLista = (props) => {
  const [candidatos, setCandidatos] = useState([]);
  const [modal, setmodal] = useState(false);
  //const [id, setId] = useState("");
  const [pages, setPages] = useState([]);
  const [candidato, setCandidato] = useState({});
  // const [message, setmessage] = useState([]);
  //const [file, setFile] = useState("");
  // const [files, setFiles] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [formacoes, setFormacoes] = useState([]);
  const [experiencias, setExperiencias] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [busca, setBusca] = useState("");

  const buscarCandidatoPorNome = async () => {
    await fetch(
      "https://www.hebertengenharia.com.br/api/buscarCandidatoPorNome",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          pagina: paginaAtual,
          paginaoffset: paginaAtual * 65 === 0 ? 65 : paginaAtual * 65,
          termo: busca,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let p = Math.ceil(result.length / 65);
        let arr = [];
        for (let index = 0; index < p; index++) {
          arr.push(index);
        }
        setPages(arr);
        setCandidatos(result);
      });
  };

  const desativar = async (id_candidato) => {
    await fetch("https://www.hebertengenharia.com.br/api/desativarcandidato", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id_candidato }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getCandidatos();
      });
  };

  const getCandidatos = async (p) => {
    let validado = !p ? 0 : p;
    await fetch("https://www.hebertengenharia.com.br/api/getcandidato", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        pagina: validado > 0 ? 65 : 0,
        paginaoffset: validado * 65 === 0 ? 65 : validado * 65,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        let p = Math.ceil(result["total"] / 65);
        let arr = [];
        for (let index = 0; index < p; index++) {
          arr.push(index);
        }
        setPages(arr);
        setPaginaAtual(validado);
        delete result["total"];
        setCandidatos(result.lista);
      });
  };

  const getFormacaoCandidato = async (_id) => {
    await fetch("https://www.hebertengenharia.com.br/api/getcandidatoformacao", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: _id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setFormacoes(result);
      });
  };

  const getIdiomaCandidato = async (_id) => {
    await fetch("https://www.hebertengenharia.com.br/api/getidiomacandidato", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: _id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setIdiomas(result);
      });
  };

  const getExperienciaCandidato = async (_id) => {
    await fetch(
      "https://www.hebertengenharia.com.br/api/getexperienciacandidato",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ id: _id }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setExperiencias(result);
      });
  };

  useEffect(() => {
    getCandidatos();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dados do candidato </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-body2  text-center">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-nome"
                        className="col-form-label"
                      >
                        Nome completo:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.nome || ""}
                        readOnly={true}
                        name="nome"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-email"
                        className="col-form-label"
                      >
                        Email:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.email || ""}
                        readOnly={true}
                        name="email"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-telefone"
                        className="col-form-label"
                      >
                        Telefone:
                      </label>
                      <InputMask
                        mask="(99)99999-9999"
                        className="form-control"
                        value={candidato.telefone || ""}
                        readOnly={true}
                        name="telefone"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-datanascimento"
                        className="col-form-label"
                      >
                        Data de nascimento:
                      </label>
                      <InputMask
                        mask="99/99/9999"
                        className="form-control"
                        value={
                          moment(candidato.datanascimento).format(
                            "DD/MM/YYYY"
                          ) || ""
                        }
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-habilitacao"
                        className="col-form-label"
                      >
                        Habilitação:
                      </label>

                      <select
                        type="text"
                        className="form-control"
                        value={candidato.habilitacao || ""}
                        readOnly={true}
                        name="habilitacao"
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="N">Não possui</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-cpf"
                        className="col-form-label"
                      >
                        CPF:
                      </label>
                      <InputMask
                        className="form-control"
                        value={candidato.cpf || ""}
                        readOnly={true}
                        name="cpf"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-areainteresse"
                        className="col-form-label"
                      >
                        Área de interesse:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.areainteresse || ""}
                        readOnly={true}
                        name="areainteresse"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Endereço</span>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="recipient-rua" className="col-form-label">
                        Rua:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.rua || ""}
                        readOnly={true}
                        name="rua"
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="recipient-rua" className="col-form-label">
                        Número:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.numero || ""}
                        readOnly={true}
                        name="numero"
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-complemento"
                        className="col-form-label"
                      >
                        Complemento:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.complemento || ""}
                        readOnly={true}
                        name="complemento"
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="recipient-cep" className="col-form-label">
                        CEP:
                      </label>
                      <InputMask
                        mask="99999-999"
                        className="form-control"
                        value={candidato.cep || ""}
                        readOnly={true}
                        name="cep"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-bairro"
                        className="col-form-label"
                      >
                        Bairro:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.bairro || ""}
                        readOnly={true}
                        name="bairro"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-cidade"
                        className="col-form-label"
                      >
                        Cidade:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.cidade || ""}
                        readOnly={true}
                        name="cidade"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-estado"
                        className="col-form-label"
                      >
                        Estado:
                      </label>
                      <select
                        className="form-control"
                        value={candidato.estado || ""}
                        readOnly={true}
                        name="estado"
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                        <option value="EX">Estrangeiro</option>
                      </select>
                    </div>
                  </div>
                </div>
              {/*  <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Formação</span>
                    <hr />
                  </div>
                </div>
                {formacoes.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-curriculo"
                          className="col-form-label"
                        >
                          Formação:
                        </label>
                        <table className="table table-sm table-bordered table-striped ">
                          <thead>
                            <tr>
                              <th scope="col">Área de Formação</th>
                              <th scope="col">Status</th>
                              <th scope="col">Nível</th>
                              <th scope="col">Conclusão</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formacoes &&
                              formacoes.map((el, i) => {
                                return (
                                  <tr key={"formacoes_" + i}>
                                    <th scope="row">{el.formacao}</th>
                                    <th scope="row">{el.statusformacao}</th>
                                    <th scope="row">{el.nivel}</th>
                                    <th scope="row">
                                      {moment(el.conclusao).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </th>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Idioma</span>
                    <hr />
                  </div>
                </div>
                {idiomas.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-curriculo"
                          className="col-form-label"
                        >
                          Idiomas:
                        </label>
                        <table className="table table-sm table-bordered table-striped ">
                          <thead>
                            <tr>
                              <th scope="col">Idioma</th>
                              <th scope="col">Nível</th>
                            </tr>
                          </thead>
                          <tbody>
                            {idiomas &&
                              idiomas.map((el, i) => {
                                return (
                                  <tr key={"idioma_" + i}>
                                    <th scope="row" width="45%">
                                      {el.idioma}
                                    </th>
                                    <th scope="row" width="45%">
                                      {el.nivel_idioma}
                                    </th>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Cursos Complementares</span>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="message-text" className="col-form-label">
                        Cursos:
                      </label>
                      <textarea
                        className="form-control"
                        value={candidato.curso || ""}
                        readOnly={true}
                        name="curso"
                        rows={6}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Experiência profissional</span>
                    <hr />
                  </div>
                </div>
                {experiencias.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-curriculo"
                          className="col-form-label"
                        >
                          Experiências profissional:
                        </label>
                        <table className="table table-sm table-bordered table-striped ">
                          <thead>
                            <tr>
                              <th scope="col">Empresa</th>
                              <th scope="col">Cargo</th>
                              <th scope="col">Data Inicío</th>
                              <th scope="col">Data Fim</th>
                              <th scope="col">Atribuições</th>
                            </tr>
                          </thead>
                          <tbody>
                            {experiencias &&
                              experiencias.map((el, i) => {
                                return (
                                  <tr key={"experiencias_" + i}>
                                    <th scope="row">{el.empresa}</th>
                                    <th scope="row">{el.cargo}</th>
                                    <th scope="row">
                                      {moment(el.inicio).format("DD/MM/YYYY")}
                                    </th>
                                    <th scope="row">
                                      {moment(el.fim).format("DD/MM/YYYY")}
                                    </th>
                                    <th scope="row">{el.atribuicoes}</th>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}   */}

                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Documentos</span>
                    <hr />
                  </div>
                </div>
                {candidato.documentos &&
                  candidato.documentos.split(",").length > 0 && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <table className="table table-sm table-bordered table-striped ">
                            <thead>
                              <tr>
                                <th scope="col">Nome do arquivo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidato.documentos.split(",").map((arq, i) => {
                                return (
                                  <tr key={"arq_" + i}>
                                    <th scope="row" width="90%">
                                      <a
                                        href={
                                          "https://www.hebertengenharia.com.br/uploads/" +
                                          arq.split(":")[1]
                                        }
                                        download={true}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        { arq.split(":")[0]+":"+arq.split("|")[1]}
                                      </a>
                                    </th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <input
          name="busca"
          onChange={(e) => {
            setBusca(e.target.value);
          }}
          value={busca || ""}
          className="form-control"
          placeholder="Buscar por nome"
          style={{ width: "90%", float: "left" }}
        />

        <button
          className="btn btn-primary"
          type="button"
          style={{ padding: 9, float: "left", marginLeft: "1%" }}
          onClick={() => {
            buscarCandidatoPorNome();
          }}
        >
          <i className="fa fa-search fa-lg " aria-hidden="true"></i>
        </button>

        <button
          className="btn btn-warning"
          type="button"
          style={{ padding: 9, float: "left", marginLeft: "1%" }}
          onClick={() => {
            setBusca("");
          }}
        >
          <i className="fa fa-trash fa-lg " aria-hidden="true"></i>
        </button>
      </div>
      <br />
      <br />
      <br />
      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Área de interesse</th>
            <th scope="col">Status</th>
            <th scope="col">Currículo/Documentos</th>
            <th scope="col" className="text-center">Visualizar</th>
            <th scope="col" className="text-center">Alterar Status</th>
          </tr>
        </thead>
        <tbody>
          {candidatos.length
            ? candidatos.map((t, index) => {
                return (
                  <tr
                    key={index + "_candidatos"}
                    style={{
                      backgroundColor:
                        t.candidato_status === 0 ? "#ffe5e3" : "#efffe3",
                    }}
                  >
                    <td>{t.nome}</td>
                    <td>{t.email}</td>
                    <td>{t.areainteresse}</td>
                    <td>{t.candidato_status === 0 ? "Não apto" : "Apto"}</td>
                    <td>
                      {t.documentos &&
                        t.documentos.split(",").map((d, i) => {
                          return (
                            <p className="link" key={"docs_" + i}>
                              <a
                                href={
                                  "https://www.hebertengenharia.com.br/uploads/" +
                                  d.split(":")[1]
                                }
                                               target="_blank"
                                        rel="noreferrer"
                              >
                                 { d.split(":")[0]+":"+d.split("|")[1]}
                              </a>
                            </p>
                          );
                        })}
                    </td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          setCandidato(t);
                          getFormacaoCandidato(t.id_candidato);
                          getIdiomaCandidato(t.id_candidato);
                          getExperienciaCandidato(t.id_candidato);
                          setmodal(true);
                        }}
                      >
                        <i className="fa fa-eye blue" aria-hidden="true"></i>
                      </span>
                    </td>
                    <td align="middle">
                      <span
                        className="icons red"
                        onClick={() => {
                          desativar(t.id_candidato);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            : null}
          <tr>
            <td colSpan={6}>
              <nav aria-label="...">
                <ul className="pagination pagination-sm  justify-content-end">
                  {pages.length ? (
                    pages.map((p, i) => {
                      return (
                        <li
                          className={
                            paginaAtual !== i ? "page-item" : "page-item active"
                          }
                        >
                          <span
                            className="page-link"
                            href="#"
                            key={"links_" + i}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              getCandidatos(p);
                            }}
                          >
                            {p + 1}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <li
                      className={
                        paginaAtual !== 0 ? "page-item" : "page-item active"
                      }
                    >
                      <span
                        className="page-link"
                        href="#"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setPaginaAtual(0);
                        }}
                      >
                        1
                      </span>
                    </li>
                  )}
                </ul>
              </nav>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default CandidatoLista;
