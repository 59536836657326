import { useState, useEffect } from "react";
import Vaga from ".";

const VagaList = (props) => {
  const [vagas, setvagas] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");
  const desativar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/desativarvagas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getvagas();
      });
  };

  const getvagas = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getvagas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setvagas(result);
      });
  };

  useEffect(() => {
    getvagas();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desativar vaga</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                Deseja realmente desativar a vaga?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    desativar();
                  }}
                >
                  Desativar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Título</th>
            <th scope="col">Data de Publicação</th>
            <th scope="col">Descrição</th>
            <th scope="col">Status</th>
            <th scope="col">Documentos Variados</th>
            <th scope="col" className="text-center">
              Anexo
            </th>
            <th scope="col" className="text-center">
              Editar
            </th>
            <th scope="col" className="text-center">
              Desativar
            </th>
          </tr>
        </thead>
        <tbody>
          {vagas.length
            ? vagas.map((vaga, index) => {
                return (
                  <tr key={index + "_v"}>
                    <th scope="row">{index}</th>
                    <td>{vaga.titulo}</td>
                    <td>
                      {vaga.data.substr(8, 2) +
                        "/" +
                        vaga.data.substr(5, 2) +
                        "/" +
                        vaga.data.substr(0, 4)}
                    </td>
                    <td>{vaga.descricao?.substr(0, 100) + "..."}</td>
                    <td>{!vaga.status ? "Desativado" : "Ativo"}</td>
                    <td>{!vaga.documentosvariados ? "Nao" : "Sim"}</td>
                    <td align="middle">
                      <a href={"/uploads/" + vaga.file}>
                        <span className="icons">
                          <i className="fa fa-paperclip" aria-hidden="true"></i>
                        </span>
                      </a>
                    </td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          props.setconteudo(
                            <Vaga inputs={vaga} editar="true" />
                          );
                          props.setTituloConteudo("Editar vaga");
                          props.setbread("Vagas  /  Listar / Editar");
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o blue"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                    <td align="middle">
                      <span
                        className="icons red"
                        onClick={() => {
                          setmodal(true);
                          setId(vaga.id);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </>
  );
};

export default VagaList;
